<template>
    <div class="site__centered">
        <div class="my-applications">
            <h2 class="my-applications__title">
                <span class="mobile-hidden">Application Submission</span>
            </h2>
            <div class="my-applications__row">
                <SideNav class="mobile-hidden"/>

                <p v-if="isLoading"></p>
                <div v-else class="my-applications__row__col-9 col-sm-12">
                    <div class="my-applications__navigation">
                        <div class="back" @click="$router.go(-1)">Back</div>
                        <div class="delete">Delete</div>
                    </div>
                    <div class="my-applications__border my-applications__profile">
                        <div class="my-applications__profile__photo">
                            <img :src="this.avatar" width="87" height="87">
                        </div>
                        <div class="my-applications__profile__details">
                            <p class="name">{{this.fullName}}</p>
                            <hr>
                            <span>DETAILS</span>
                            <div class="my-applications__profile__details__container">
                                <div :style="{'display': this.phone ? 'block': 'none'}">
                                    <span>Phone</span><br>
                                    <span>{{this.phone}}</span>
                                </div>
                                <div :style="{'display': this.email ? 'block': 'none'}">
                                    <span>Email</span><br>
                                    <span>{{this.email}}</span>
                                </div>
<!--                                <div>-->
<!--                                    <span>Age & Date of birth</span><br>-->
<!--                                    <span>2</span>-->
<!--                                </div>-->
<!--                                <div>-->
<!--                                    <span>Father / Guardian</span><br>-->
<!--                                    <span>2</span>-->
<!--                                </div>-->
<!--                                <div>-->
<!--                                    <span>City</span><br>-->
<!--                                    <span>2</span>-->
<!--                                </div>-->
<!--                                <div>-->
<!--                                    <span>State</span><br>-->
<!--                                    <span>2</span>-->
<!--                                </div>-->
                            </div>
                        </div>
                    </div>
                    <p class="my-applications__details-title">Application Details</p>
                    <div class="my-applications__details">
                        <ApplicationSubmissionItem
                                v-for="application in this.applicationsList"
                                :key="application.id"
                                :application="application"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from '@axios'
    import router from '@/router'
    import ApplicationSubmissionItem from './ApplicationSubmissionItem'
    import SideNav from '@/components/site/SideNav.vue'

    export default {
        name: "ApplicationSubmission",
        components: {
            ApplicationSubmissionItem,
            SideNav,
        },
        data: () => ({
            applicationsList: [],
            avatar: null,
            email: null,
            phone: null,
            address: null,
            programName: null,
            fullName: null,
            isLoading: true,
        }),
        mounted() {
            const applicationID = router.currentRoute.params.id;
            this.getApplication(applicationID);
        },
        methods: {
            async getApplication(id) {
                this.$emit('loading', true)

                axios
                    .get(`/applications/${id}`)
                    .then(response => {
                        // this.applicationsList = response.data.data;
                        this.applicationsList = response.data.data.application_fields;
                        this.avatar = response.data.data.user.avatar;
                        this.email = response.data.data.user.email;
                        this.phone = response.data.data.user.phone;
                        this.address = response.data.data.user.address;
                        this.fullName = response.data.data.user.full_name;
                        this.programName = response.data.data.program;
                        
                        this.isLoading = false
                        this.$emit('loading', false)
                    })
                    .catch(error => console.log(error))
                    .finally(() => {
                        this.$emit('loading', false)
                    })
            },
        },
    }
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/colors/colors';

.my-applications__title,
.my-applications__navigation,
.my-applications__profile,
my-applications__profile__details__container {
    display: flex;
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
}

.my-applications__title {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
}

.my-applications__title .message__success {
    padding: 30px 40px;
    background: $secondary_bg;
    border-radius: 25px;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    white-space: pre-wrap;
}

.my-applications__navigation .back, .my-applications__navigation .delete {
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
}

.my-applications__navigation .back {
    color: $primary;
}

.my-applications__navigation .delete {
    color: #EE2F23;
}

.my-applications__profile,
.my-applications__details__box {
    padding: 35px;
    margin-bottom: 25px;
}

.my-applications__profile {
    margin-top: 30px;
}


.my-applications__profile__details {
    width: calc(100% - 87px);
    padding-left: 25px;
}

.my-applications__profile__details p {
    color: $secondary;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
}

.my-applications__profile__details hr {
    border-bottom: 1px solid $secondary;
    opacity: 0.1;
}

.my-applications__profile__details span {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    color: #000000;
    opacity: 0.3;
}

.my-applications__profile__details__container {
    display: flex;
    flex-wrap: wrap;
}

.my-applications__profile__details__container div {
    width: 33.333333%;
    margin: 10px 0;
}

.my-applications__profile__details__container div span:first-child {
    color: $secondary;
    opacity: 0.7;
    font-size: 14px;
    line-height: 32px;
}

.my-applications__profile__details__container div span:last-child {
    color: $secondary;
    font-size: 15px;
    font-weight: bold;
    line-height: 18px;
    opacity: 1;
}

.my-applications__details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 25px;
    &-title {
        font-weight: bold;
    }
}

.my-applications__details__box {
    flex: 0 0 48%;
    max-width: 48%;
}

@media screen and (max-width: 767px) {
    .my-applications {
        &__title {
            .message__success {
                font-size: 16px;
                line-height: 20px;
                padding: 20px;
            }
        }
    }
    
    .col-sm-12 {
        padding-right: 15px;
        padding-left: 15px;
        flex: 0 0 100%;
        max-width: 100%;
    }
}
</style>
