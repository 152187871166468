<template>
    <div
            class="my-applications__border my-applications__details__box"
            :style="{'display': application.result ? 'block': 'none'}"
    >
        <p class="my-applications__details__box-title">{{ application.field_label | capitalize }}</p>
        <span>{{ application.result }}</span>
    </div>
</template>

<script>
    export default {
        props: {
            application: {
                type: Object,
                default: () => {
                },
            },
        },
        filters: {
            capitalize: function (value) {
                if (!value) return ''
                
                const arr = value.split(" ").map(el => {
                    return el.charAt(0).toUpperCase() + el.slice(1)
                });
                return arr.join(" ")                
            }
        }
    }
</script>

<style scoped>
.my-applications__details__box-title {
    font-weight: bold;
    margin-bottom: 10px;
}
</style>